<template>
  <div class="VTable__wrap">
    <div class="shadow main" ref='main'>
      <el-table
        v-loading="loading"
        highlight-current-row
        :default-expand-all="defaultExpandAll"
        row-key="id"
        :element-loading-text="loadingText"
        :data="data"
        :height="height"
        :header-cell-style="{background:'#FAFAFA'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="showSelect"
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          v-for="item in field"
          :key="item.name"
          :prop="item.name"
          :label="item.label"
          :width="item.width?item.width:''"
          :fixed="item.fixed"
        >
          <template slot-scope="{ row, $index }">
            <slot :name="item.name" :row="row" :$index="$index">{{ row[item.name] }}</slot>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='pagination__wrap' v-if='hasPagionation'>
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="page"
        :page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 50, 100, 150]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'VTable',
    props: {
      title: { required: false, type: String, default: "" },
      addText: { required: false, type: String, default: "新增" },
      lazy: { required: false, type: Boolean, default: false },
      hasPagionation: { required: false, type: Boolean, default: false },
      showOverflowTooltip: { required: false, type: Boolean, default: true },
      exportText: { required: false, type: String, default: "导出" },
      loading: { type: Boolean, default: false },
      loadingText: { type: String, default: "加载中" },
      auth: { required: false, type: Array, default: () => [] },
      field: { required: false, type: Array, default: () => [] },
      data: { type: Array, default: () => [] },
      page: { required: false, type: Number, default: 1 },
      pageSize: { required: false, type: Number, default: 10 },
      total: { required: false, type: Number, default: 0 },
      defaultExpandAll: { require: false, type:Boolean, default: false},
      showSelect: { required: false, type: Boolean, default: false },
    },
    data() {
      return {
        height: 0
      }
    },
    methods: {
      handleSizeChange(pageSize) {
        this.$emit("handleSizeChange", pageSize);
      },
      handleCurrentChange(page) {
        this.$emit("handleCurrentChange", page)
      },
      // 批量选择
      handleSelectionChange(batchData) {
        this.$emit('handleSelectionChange', batchData)
      }
    },
    mounted() {
      this.$nextTick(() => {
        console.log(`main的高度：${this.$refs.main.offsetHeight}`);
        this.height = this.$refs.main.offsetHeight || 500
      })
    }
  }
</script>
<style scoped lang="scss">
  .VTable__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .main {
      flex: 1;
      margin-bottom: 15px;
    }
    .pagination__wrap {
      text-align: right;
      padding: 0 50px;
    }
  }
</style>
