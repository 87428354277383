import {sign} from "@/api/http"
const common = {
  deepClone(origin, target) {
    var _this = this;
    var target = target || {};
    for (var prop in target) {
      if (target.hasOwnProperty(prop)) {
        if (target[prop] !== null && typeof target[prop] === 'object') {
          origin[prop] = Object.prototype.toString.call(target[prop]) === '[object Array]' ? [] : {};
          _this.deepClone(origin[prop], target[prop]);
        } else {
          origin[prop] = target[prop];
        }
      }
    }
  }
}

export let exportData = (obj, url)=>{
  obj.timestamp = new Date().getTime();
  obj.token = localStorage.getItem('token');
  let str = '';
  Object.keys(obj).forEach((item)=>{
    str += `&${item}=${obj[item]}`
  })
  let data = sign(obj);
  console.log(`http://apisettle.jszhr.test${url}?sign=${data}${str}`,3)
  window.open(`http://apisettle.jszhr.test${url}${str}&sign=${data}`)
}

export default common;


